import { EventAggregator } from 'aurelia-event-aggregator';
import { Menus, MENU_AREA } from 'services/menus';
import { Security } from 'common/security';
import { c } from 'common/common';
import { ROLE } from 'common/constants';

export class BigButtonMenu {
    static inject = [EventAggregator, Menus, Security];
    _ea;
    _menus;
    _security;

    menus = [];
    _handlers = [];

    constructor(ea, menus, security) {
        this._ea = ea;
        this._menus = menus;
        this._security = security;

        this.canEdit = this._security.isInRole([ROLE.Admin, ROLE.DashboardAdmin]);
    }

    async attached() {
        this._handlers.push(this._ea.subscribe('dnd:didEnd', async() => {
            window.setTimeout(() => this._menuReordered(), 0);
        }));
        this._load();
    }

    async _menuReordered() {
        if (!this.canEdit) return;
        try {
            const orderedIds = [];
            this.menus.forEach(m => {
                orderedIds.push(m.id);
            });
            await this._menus.setMobileMenuOrdering(orderedIds);
        } catch (err) {
            console.log(err);
        }
    }

    async _reload() {
        this.menus = [];
        await this._load();
    }

    async _load() {
        try {
            this.menus = await this._menus.byArea(MENU_AREA.bigButtons);
        } catch (err) {
            console.log(err);
        }
    }

    toggleAdminTools() {
	    this.adminToolsOn = !this.adminToolsOn;
	}

    async addNbr() {
        try {
            await this._menus.addToMobileMenu(null, 'NBR', 'nav-big-buttons-bunny', null, null, '#', 'fa-duotone fa-rabbit', null);
            this._reload();
        } catch (err) {
            console.log(err);
        }
    }

    async addShareLink() {
        try {
            await this._menus.addToMobileMenu(null, 'ShareLink', 'nav-big-buttons-share', null, null, '#', 'fa-duotone fa-share-nodes', null);
            this._reload();
        } catch (err) {
            console.log(err);
        }
    }

    async removeBigButton(menu) {
        try {
            this.processing = true;
            await this._menus.removeFromMobileMenu(menu.id);
            this._reload();
        } catch (err) {
            console.log(err);
        } finally {
            this.processing = false;
        }
    }

    bigButtonClick(type) {
        if (!type) return true;
        switch (type.toLowerCase()) {
            case 'nbr': this._openNbr(); return false;
            case 'sharelink': this._security.openShareLink(); return false;
        }
        return true;
    }

    _openNbr() {
        const nbrLink = c.Helpers.replaceVariables(this._security, `https://form.jotform.com/210443910285046?agentName[first]={{firstName}}&agentName[last]={{lastName}}&agentEmail={{email}}&agentMobile[full]={{cell}}&baseshopName[first]={{agencyFirstName}}&baseshopName[last]={{agencyLastName}}&baseshopEmail={{agencyEmail}}&baseshopMobile[full]={{agencyCell}}&agentNumber={{agentID}}`, true);
        this._ea.publish(c.EventKeys.site.openLightbox, { embedSrc: nbrLink });
    }
}