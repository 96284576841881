import { bindable, bindingMode } from 'aurelia-framework';
import { LOB } from 'common/constants';
import { c } from 'common/common';

export class LobMultiselect {
    static inject = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay }) values;

    id;
    valuesChecked = {};
    linesOfBusiness = LOB.all();

    constructor() {
        this.id = c.Helpers.uniqueId();
        this.linesOfBusiness.forEach(lob => {
            this.valuesChecked[lob] = false;
        });
    }

    valuesChanged(newValue, oldValue) {
        if (oldValue && newValue && JSON.stringify(oldValue) !== JSON.stringify(newValue)) this._initialized = false;
        if (this._initialized) return;
        if (!this.values || !this.values.length) return;
        try {
            this.values.forEach(v => {
                this.valuesChecked[v] = true;
            });
            this._initialized = true;
        } catch (err) {
            console.log(err);
        }
    }

    lobChecked(lob) {
        if (!this.values) return;
        const updatedValues = [];
        this.linesOfBusiness.forEach(lob => {
            if (this.valuesChecked[lob]) updatedValues.push(lob);
        });
        this.values = updatedValues;
    }
}
