import { bindable } from 'aurelia-framework';
import { LpfnUtil } from 'common/utils';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Menus, MENU_AREA } from 'services/menus';
import { c } from 'common/common';

export class SidebarMenu {
    static inject = [Router, EventAggregator, Security, Menus];
    @bindable area;
    router;
    _ea;
    security;
    _menus;

    membersNavEl;

    menuArea;
    menu;

    @bindable collapsed = false;

    _handlers = [];

    constructor(router, ea, security, menus) {
        this.router = router;
        this._ea = ea;
        this.security = security;
        this._menus = menus;
    }

    async attached() {
        this._isAttached = true;
        if (this.area) await this.loadMenu(this.area);
        this._handlers.push(this._ea.subscribe(c.EventKeys.menu.changed, (data) => {
            this.loadMenu(data.area);
        }));
        this._handlers.push(this._ea.subscribe(c.EventKeys.menu.updated, (data) => {
            if (this.menuArea !== data.area) return; // Only need to load the menu if the currently displayed menu has been updated
            this.menuArea = undefined;
            this.loadMenu(data.area);
        }));
        this._handlers.push(this._ea.subscribe(c.EventKeys.navigation.changing, (data) => {
            this._setActiveMenu(data.fragment);
        }));
        this._handlers.push(this._ea.subscribe(c.EventKeys.loginAuthenticationUpdated, (data) => {
            if (data.authenticated) return;
            this.menuArea = undefined;
        }));
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    onMenuClick(link) {
        if (!link.lightboxUrl) return true;
        const lightboxUrl = c.Helpers.replaceVariables(this.security, link.lightboxUrl, true);
        this._ea.publish(c.EventKeys.site.openLightbox, { embedSrc: lightboxUrl });
        return false;
    }

    areaChanged() {
        if (!this._isAttached) return;
        if (!this.area) return;
        this.loadMenu(this.area);
    }

    async loadMenu(area) {
        try {
            if (this.menuArea === area) return;
            this.menuArea = area;
            this.menus = undefined;
            if (!area) return;
            switch (area) {
                case MENU_AREA.members:
                    this.menus = await this._menus.byArea(MENU_AREA.members);
                    break;
                case MENU_AREA.admin:
                    this.menus = await this._menus.byArea(MENU_AREA.admin);
                    break;
                case MENU_AREA.onboarding:
                    this.menus = await this._menus.byArea(MENU_AREA.onboarding);
                    break;
            }
            if (this.router.currentInstruction) {
                const fragment = this.router.currentInstruction.parentInstruction
                    ? this.router.currentInstruction.parentInstruction.fragment
                    : this.router.currentInstruction.fragment;
                this._setActiveMenu(fragment);
            }
        } catch (err) {
            console.log(err);
        }
    }

    _setActiveMenu(fragment) {
        if (!this.menus) return;
        this._clearActiveMenu();
        for (let menu of this.menus) {
            if (this._setActiveSubmenu(fragment, menu, menu)) return;
        }
    }

    _clearActiveMenu() {
        if (!this.menus) return;
        this.menus.forEach(m => {
            m.isActive = false;
            this._clearActiveSubmenu(m);
        });
    }

    _clearActiveSubmenu(subMenu) {
        if (!subMenu.items) return;
        subMenu.items.forEach(sm => {
            sm.isActive = false;
            this._clearActiveSubmenu(sm);
        });
    }

    _setActiveSubmenu(fragment, mainMenu, menu) {
        const menuFragment = menu.href ? menu.href.substr(1) : '';
        if (fragment === menuFragment) {
            mainMenu.isActive = true;
            menu.isActive = true;
            return true;
        }
        if (menu.href === 'if it is the same') return true; // no need to run through any of the sub items
        if (!menu.items || !menu.items.length) return false;
        for (let subMenu of menu.items) {
            if (this._setActiveSubmenu(fragment, mainMenu, subMenu)) return true;
        }
        return false;
    }

    showMenuItem(menuItem) {
        if (menuItem.ifNotSecurity) {
            return !this.security[menuItem.ifNotSecurity];
        } else if (menuItem.ifSecurity) {
            return this.security[menuItem.ifSecurity];
        } else if (menuItem.ifRole) {
            return this.security.isInRole(menuItem.ifRole);
        } else if (menuItem.showFor && menuItem.showFor.length) {
            let display = false;
            menuItem.showFor.forEach(sf => {
                switch (sf) {
                    case 'admin': if (this.security.isAdmin) display = true; break;
                    case 'masterAgency': if (this.security.isMasterAgency()) display = true; break;
                    case 'agency': if (this.security.isAgency()) display = true; break;
                    case 'team': if (this.security.isTeam()) display = true; break;
                    case 'agent': display = true; break;
                }
            });
            return display;
        }
        return true;
    }

    toggleMenu(id) {
        try {
            const item = document.getElementById(`sidebar-menu-${id}`);
            const sub = document.getElementById(`sidebar-menu-${id}-sub`);
            if (LpfnUtil.hasClass(item, 'show')) {
                LpfnUtil.addClass(item, 'hiding');
                LpfnUtil.slideUp(sub, 350, () => {
                    LpfnUtil.removeClass(item, 'hiding');
                    LpfnUtil.removeClass(item, 'show');
                    LpfnUtil.removeClass(sub, 'show');
                    LpfnUtil.removeClass(item, 'hover');
                });
            } else {
                LpfnUtil.addClass(item, 'hover');
                LpfnUtil.addClass(item, 'showing');
                LpfnUtil.slideDown(sub, 350, () => {
                    LpfnUtil.removeClass(item, 'showing');
                    LpfnUtil.addClass(item, 'show');
                    LpfnUtil.addClass(sub, 'show');
                });
            }
        } catch (err) {
            console.log(err);
        }
    }
}