import { bindable } from 'aurelia-framework';
import { Members } from 'services/members';

export class MemberLicenses {
    static inject = [Members];
    @bindable memberId;

    _members;

    constructor(members) {
        this._members = members;
    }

    memberIdChanged() {
        this._load();
    }

    async _load() {
        if (!this.memberId) return;
        try {
            this.licenses = await this._members.licenses(this.memberId);
        } catch (err) {
            console.log(err);
        }
    }

    lettersOfAuthority(license) {
        try {
            if (!license || !license.lettersOfAuthority || !license.lettersOfAuthority.length) return '';
            const loaDescriptions = [];
            license.lettersOfAuthority.forEach(loa => loaDescriptions.push(loa.description));
            return loaDescriptions.join('; ');
        } catch (err) {
            console.log(err);
            return '';
        }
    }
}
