import { bindable, computedFrom } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Reports } from 'services/reports';
import { Products } from 'services/products';
import { WIDGET, KPI, LOB } from 'common/constants';
import { TimePeriodNavigatorBase } from 'resources/base-classes/time-period-navigator-base';
import moment from 'moment';

export class Metrics extends TimePeriodNavigatorBase {
    static inject = [EventAggregator, Reports, Products];
    _reports;
    _products;

    @bindable metric;
    @bindable sync = true;
    @bindable scroll = true;

    GroupBy = WIDGET.GroupBy;

    _reports;

    constructor(ea, reports, products) {
        super('metrics', ea);
        this._reports = reports;
        this._products = products;
    }

    attached() {
        this._isAttached = true;
        this._attached();
        this._loadData();
        this._loadProductTypes();
    }

    async metricChanged(newValue, oldValue) {
        if (!this._isAttached) return;
        this._loadData();
    }

    async _loadProductTypes() {
        try {
            const productTypes = await this._products.typesByLOB();
            this.totalLifeProductTypes = productTypes.find(x => x.lineOfBusiness === LOB.Life).productTypes.length;
        } catch (err) {
            console.log(err);
        }
    }

    @computedFrom('metric')
    get groupByTitleKey() {
        if (!this.metric) return undefined;
        switch (this.metric.groupBy) {
            case WIDGET.GroupBy.Agent: return 'by-agent-metrics-title';
            case WIDGET.GroupBy.Team: return 'by-team-metrics-title';
            case WIDGET.GroupBy.Agency: return 'by-agency-metrics-title';
            case WIDGET.GroupBy.Carrier: return 'by-carrier-metrics-title';
        }
    }

    async refreshData() {
        this.refreshing = true;
        if (this.report) this.report = undefined;
        await this._loadData();
        this.refreshing = false;
    }

    async _loadData(fromSync = false) {
        if (!this.metric) return;
        try {
            this.loading = true;
            this.metrics = undefined;
            this.dgRecords = undefined;
            this.initializeTimePeriodNavigation(fromSync);

            this.metrics = await this._reports.metrics(this.metric, this.asOfDate.toISOString(true));

            this.timePeriod = WIDGET.Period.toReportTimePeriod(this.metric.period);
            this.currentPeriod = this.metrics.timePeriod;
            this.setTimePeriodNavigationDisplays();
            this._loadChart();
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    _loadChart() {
        const chartData = [];
        this.metrics.records.forEach(r => {
            chartData.push(
                r.member
                    ? { id: r.member.id, name: r.member.fullName, shortName: r.member.initials, value: r.value }
                    : { id: r.carrier.id, name: r.carrier.name, shortName: r.carrier.code, value: r.value });
        });
        this.chartData = chartData;
    }

    async drilldown(drilldownDetail) {
        try {
            if (!drilldownDetail) return;
            this.drilldownName = drilldownDetail.name;
            this.loadingDetails = true;
            this.drilldownDetails = await this._reports.metrics(this.metric, this.asOfDate.toISOString(true), drilldownDetail.id);
            this.drilldownDetails.forEach(ddd => {
                const key = KPI.PremiumType.toPolicyPremium(this.metric.premiumType);
                ddd.showPremium = key ? ddd[key] ?? '' : '';
            });
            this.showDetails();
        } catch (err) {
            console.log(err);
        } finally {
            this.loadingDetails = false;
        }
    }

    async showDetails() {
        this.showDrawer = moment().format();
    }

    drawerClosed() {
    }
}
