import { bindable } from 'aurelia-framework';
import { Messages, MESSAGE_STATUS } from 'services/messages';

export class ViewMessages {
    static inject = [Messages];
    _messages;

    @bindable type;
    @bindable aboutId;

    messageFilters = [{ value: '', keys: ['subject', 'from.fullName', 'from.email', 'content'] }];
    pageSize = 25;

    constructor(messages) {
        this._messages = messages;
    }

    typeChanged() {
        this._load();
        if (this.type) this.titleKey = `${this.type.toLowerCase()}-messages`;
    }

    aboutIdChanged() {
        this._load();
    }

    async _load() {
        if (!this.type || !this.aboutId) return;
        try {
            this.messages = await this._messages.listFor(this.type, this.aboutId);
        } catch (err) {
            console.log(err);
        }
    }

    toggleMessage(message) {
        message.showFullMessage = !message.showFullMessage;
    }
}
