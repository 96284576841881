import { bindable } from 'aurelia-framework';
import { Sites } from 'services/sites';
import { Device } from 'common/ui';
import environment from './../../../../config/environment.json';
import { LpfnUtil } from 'common/utils';

export class Sidebar {
    static inject = [Sites, Device];
    _sites;
    _device;

    @bindable area;
    @bindable showMenu = true;

    KEY = 'lpfn.sidebar.collapsed';
    collapsed = false;

    constructor(sites, device) {
        this._sites = sites;
        this._device = device;
    }

    async attached() {
        try {
            const collapsed = localStorage.getItem(this.KEY);
            this.collapsed = collapsed === 'true' ? true : false;
            if (this._device.isMobile()) this.collapsed = false;
            this._initializeMenu();
            this.theme = await this._sites.theme();
            this.lightLogo = `${environment.static}/${this.theme.light.logoPath}`;
            this.darkLogo = `${environment.static}/${this.theme.dark.logoPath}`;
            this.iconLogo = `${environment.static}/${this.theme.logoIconPath}`;

            LpfnUtil.addResizeHandler(() => {
                if (!this.collapsed) return;
                if (!this._device.isMobile()) return;
                this.collapsed = false;
                this._initializeMenu();
            });
        } catch (err) {
            console.log(err);
        }
    }

    toggleMenu() {
        this.collapsed = !this.collapsed;
        this._initializeMenu();
    }

    _initializeMenu() {
        try {
            localStorage.setItem(this.KEY, this.collapsed);
            if (this.collapsed) {
                document.body.setAttribute('data-kt-app-layout', 'mini-sidebar');
            } else {
                document.body.setAttribute('data-kt-app-layout', 'light-sidebar');
            }
        } catch (err) {
            console.log(err);
        }
    }
}