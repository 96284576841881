import { customElement, bindable } from 'aurelia-framework';
import { Members } from 'services/members';
import { Notifier } from 'common/ui';
import { Security } from 'common/security';

@customElement('member-levels')
export class MemberLevels {
    static inject = [Members, Notifier, Security];
    @bindable member;

    levelType = 'Life';
    _members;
    _notifier;
    _security
    requestedLevel;
    comments;
    recentLevelChanges = [];
    levelOptions;
    canSeeLevels = true;

    constructor(members, notifier, security) {
        this._members = members;
        this._notifier = notifier;
        this._security = security;
    }

    attached() {
        this._setCanSeeLevel();
    }

    _setCanSeeLevel() {
        this.canSeeLevels = true;
        if (!this.member) return;
        this.canSeeLevels = this._security.canSeeLevel(this.member.id);
    }

    requestLevelChange(levelType) {
        this.levelType = levelType;
        const levelTypeOptions = this.options[levelType.toLowerCase()];
        this.levelOptions = levelTypeOptions;
        this.showForm = true;
    }

    async memberChanged() {
        try {
            if (!this.member) return;
            this._setCanSeeLevel();
            this.options = await this._members.levelChangeOptions(this.member.id);
        } catch (err) {
            console.log(err);
        } finally {
            if (!this.recentLevelChanges) this.recentLevelChanges = [];
        }
    }

    cancelLevelChange() {
        this.showForm = false;
        this.requestedLevel = undefined;
        this.comments = '';
        this.levels = undefined;
    }

    async sendLevelChange() {
        try {
            await this._members.requestLevelChange(this.member.id, this.levelType, this.requestedLevel, this.comments);
            this._notifier.success('request-level-change-sent');
            this.cancelLevelChange();
            this.memberChanged();
        } catch (err) {
            this._notifier.generalError();
        }
    }

    override() {
        this.levelOptions.canRequestChange = true;
    }
}
