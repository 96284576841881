import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { I18n } from 'common/i18n';
import { Members } from 'services/members';
import { TextareaToHtmlValueConverter } from 'resources/value-converters/textarea-to-html';
import { AgentNumberStatusValueConverter } from 'resources/value-converters/agent-number-status';
import { DialogService } from 'aurelia-dialog';
import { LevelNameValueConverter } from 'resources/value-converters/level-name';
import { FormatPhoneValueConverter } from 'resources/value-converters/format-phone';
import { BoolToYesNoValueConverter } from 'resources/value-converters/bool-to-yesno';
import { c } from 'common/common';
import { ABOUT_TYPE, AGENT_PROPERTY } from 'common/constants';
import { EditToDo } from '../to-dos/dialogs/edit-to-do';
import { EditNote } from '../notes/dialogs/edit-note';
PLATFORM.moduleName('../to-dos/dialogs/edit-to-do');
PLATFORM.moduleName('../notes/dialogs/edit-note');
import moment from 'moment';

export class MemberName {
    static inject = [EventAggregator, DialogService, I18n, Members, TextareaToHtmlValueConverter, AgentNumberStatusValueConverter, LevelNameValueConverter, FormatPhoneValueConverter, BoolToYesNoValueConverter];
    @bindable member;
    @bindable showIcon = true;
    @bindable iconSize = 'sm';
    @bindable showName = true;
    @bindable showBadges = true;
    @bindable canViewProfile = true;
    @bindable showActions = false;
    @bindable showProperties;
    @bindable carrierId = false;
    @bindable cardTab;
    @bindable nameCss;
    _ea;
    _dialogService;
    _i18n;
    _members;
    _textareaToHtmlValueConverter;
    _agentNumberStatusValueConverter;
    _levelNameValueConverter;
    _formatPhoneValueConverter;
    _boolToYesNoValueConverter;

    carrierNotes = '';
    elId;
    agentProperties = [];

    constructor(ea, dialogService, i18n, members, textareaToHtmlValueConverter, agentNumberStatusValueConverter, levelNameValueConverter, formatPhoneValueConverter, boolToYesNoValueConverter) {
        this._ea = ea;
        this._dialogService = dialogService;
        this._i18n = i18n;
        this._members = members;
        this._textareaToHtmlValueConverter = textareaToHtmlValueConverter;
        this._agentNumberStatusValueConverter = agentNumberStatusValueConverter;
        this._levelNameValueConverter = levelNameValueConverter;
        this._formatPhoneValueConverter = formatPhoneValueConverter;
        this._boolToYesNoValueConverter = boolToYesNoValueConverter;
        this.elId = c.Helpers.uniqueId();
    }

    nameFontSize() {
        switch (this.iconSize) {
            case 'xl': return 'fs-3 fw-bold';
            case 'lg': return 'fs-3 fw-bold';
            case 'md': return 'fs-4 fw-bold';
            default: return 'fs-6 fw-semibold';
        }
    }

    openProfile(ev) {
        ev.stopPropagation();
        ev.preventDefault();
        this._ea.publish(c.EventKeys.site.openProfile, { memberId: this.member.id, tab: this.cardTab });
    }

    addToDo() {
        const model = { type: ABOUT_TYPE.Agent, aboutId: this.member.id, aboutName: this.member.fullName, toDo: undefined };
        this._dialogService.open({ viewModel: EditToDo, model, ignoreTransitions: true });
    }

    addNote() {
        const model = { type: ABOUT_TYPE.Agent, aboutId: this.member.id, note: undefined };
        this._dialogService.open({ viewModel: EditNote, model, ignoreTransitions: true });
    }

    showPropertiesChanged() {
        try {
            this.agentProperties = [];
            if (!this.showProperties || !this.showProperties.length) return;
            this.showProperties.forEach(id => {
                const ap = AGENT_PROPERTY.find(id);
                if (!ap) return;
                this.agentProperties.push({
                    label: ap.translationKey,
                    value: this._agentPropertyValue(ap),
                });
            });
        } catch (err) {
            console.log(err);
        }
    }

    _agentPropertyValue(ap) {
        try {
            let value = this.member[ap.id];
            if (value === undefined || value === null || value === '') return '';
            let formattedValue = value;
            switch (ap.type) {
                case 'String': break;
                case 'Level': formattedValue = this._levelNameConverter.toView(value); break;
                case 'Phone': formattedValue = this._formatPhoneValueConverter.toView(value); break;
                case 'Date': formattedValue = moment(value).format('l'); break;
                case 'Boolean': formattedValue = this._boolToYesNoValueConverter.toView(value); break;
                default: break;
            }
            return formattedValue;
        } catch (err) {
            return this.member[ap.id];
        }
    }

    async loadCarrierNotes() {
        if (this.loadingCarrierNotes || this.carrierNotes) return;
        try {
            this.loadingCarrierNotes = true;
            const data = await this._members.getAgentNumber(this.member.id, this.carrierId);
            if (!data || data.length !== 1) return;
            const notes = [];
            data[0].agentNumbers.forEach(an => {
                const anNotes = []
                if (an.agentNumber) anNotes.push(`<div><b>${an.agentNumber}</b></div>`);
                if (an.status) anNotes.push(`<div><b>${this._agentNumberStatusValueConverter.toView(an.status)}</b></div>`);
                if (an.assignedDate) anNotes.push(`<div class="lpfn-caption">${moment(an.assignedDate).format('llll')}</div>`);
                else if (an.commentDate) anNotes.push(`<div class="lpfn-caption">${moment(an.commentDate).format('llll')}</div>`);
                if (an.comments) anNotes.push(`<div>${this._textareaToHtmlValueConverter.toView(an.comments)}</div>`);
                notes.push(anNotes.join(''));
            });
            if (notes.length === 0) notes.push(this._i18n.tr('no-carrier-numbers'));
            this.carrierNotes = notes.join('<hr>');
            this.updateCarrierNotesTooltip = moment().valueOf();
        } catch (err) {
            console.log(err);
        } finally {
            this.loadingCarrierNotes = false;
        }
    }
}
