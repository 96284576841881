import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { LpfnUtil } from 'common/utils';
import { createPopper } from '@popperjs/core';
import { eventListener } from 'common/common';

export class CollapsedMenu {
    static inject = [Element, EventAggregator];
    element;
    _ea;

    @bindable trigger = 'click';
    @bindable options = {};
    @bindable close;
    @bindable mouseEnterDebounce = 200;
    @bindable menuPlacement = 'right-start';
    @bindable menuOverflow = false;
    @bindable menuOffset;
    @bindable menuFlip = false;

    _defaultOptions = {
        zindex: 105
    };

    @bindable triggerEl;
    menuEl;

    _handlers = [];
    _menuHandlers = [];

    constructor(element, ea) {
        this.element = element;
        this._ea = ea;
        this.id = LpfnUtil.getUniqueId('dropdown');
        this.element.id = this.id;
    }

    attached() {
        this._init();
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
        this._menuHandlers.forEach(h => h.dispose());
        this._menuHandlers = [];
    }

    _init() {
        this.options = LpfnUtil.deepExtend({}, this._defaultOptions, this.options);
        this.uid = LpfnUtil.getUniqueId('menu');
        this.disabled = false;

        this.element.setAttribute('data-lpfn-menu', 'true');

        this._update();
    }

    onTriggerMouseenter() {
        if (this.disabled === true) return;
        if (this.trigger !== 'hover' ) return;
        this._hoveringMenu = false;
        this._hoveringTrigger = true;
        this.show();
    }

    onTriggerMouseleave() {
        if (this._hoveringMenu) return;
        if (this.disabled === true) return;
        if (this.trigger !== 'hover') return;
        this._hoveringTrigger = false;
        this.hide();
    }

    onMenuMouseenter() {
        if (this.disabled === true) return;
        if (this.trigger !== 'hover') return;
        this._hoveringMenu = true;
        this._hoveringTrigger = false;
        this.show();
    }

    onMenuMouseleave() {
        if (this.disabled === true) return;
        if (this.trigger !== 'hover') return;
        if (this._hoveringTrigger) return;
        this._hoveringMenu = false;
        this.hide();
    }

    onClick() {
        this.toggle();
    }

    destroy() {
    }

    closeChanged() {
        if (!this.close) return;
        this.hide();
    }

    toggle() {
        if (this._isMenuShown() === true) {
            this.hide();
        } else {
            this.show();
        }
    }

    show() {
        if (this._isMenuShown() === true) return;
        this._showDropdown();
        this._menuHandlers.push(eventListener(document.body, 'click', (e) => {
            if (e.target === this.triggerEl
                || document.getElementById(this.id).isSameNode(e.target)
                || document.getElementById(this.id).contains(e.target)) {
                return true;
            }
            e.stopPropagation();
            this.hide();
            return false;
        }));
    }

    hide() {
        if (this._isMenuShown() === false) return;
        this._hideDropdown();
        this._menuHandlers.forEach(h => h.dispose());
        this.element.dispatchEvent(new CustomEvent('closed', { bubbles: true, detail: {} }));
    }

    _reset(item) {        
        if (this._hasItemSub(item) === false) return;
        var sub = this.menuEl;
        LpfnUtil.removeClass(item, 'hover'); 
        LpfnUtil.removeClass(item, 'show'); 
        LpfnUtil.removeClass(sub, 'show'); 
    }

    _update() {
        var items = this.element.querySelectorAll('.menu-item[data-lpfn-menu-trigger]');

        if (items && items.length > 0) {
            for (var i = 0, len = items.length; i < len; i++) {
                this._reset(items[i]);
            }
        }
    }

    _isTriggerElement(item) {
        return (this.triggerElement === item) ? true : false;
    }

    _isMenuShown() {
        if (!this.menuEl) return false;

        if (LpfnUtil.hasClass(this.menuEl, 'show') === true && this.menuEl.hasAttribute('data-popper-placement') === true) {
            return true;
        } else {
            return false;
        }
    }

    _isItemDropdownPermanent(item) {
        return this._getOptionFromElementAttribute(item, 'permanent') === true ? true : false;
    }

    _isItemParentShown(item) {
        return LpfnUtil.parents(item, '.menu-item.show').length > 0;
    }

    _isItemSubElement(item) {
        return LpfnUtil.hasClass(item, 'menu-sub');
    }

    _hasItemSub(item) {
        return (LpfnUtil.hasClass(item, 'menu-item') && item.hasAttribute('data-lpfn-menu-trigger'));
    }

    _showDropdown() {
        var width = this._getOptionFromElementAttribute(this.triggerEl, 'width');
        var height = this._getOptionFromElementAttribute(this.triggerEl, 'height');

        var zindex = this.options.zindex;
        var parentZindex = LpfnUtil.getHighestZindex(this.triggerEl);

        // Apply a new z-index if dropdown's toggle element or it's parent has greater z-index // update
        if (parentZindex !== null && parentZindex >= zindex) {
            zindex = parentZindex + 1;
        }

        if (zindex > 0) {
            LpfnUtil.css(this.menuEl, 'z-index', zindex);
        }

        if (width !== null) {
            LpfnUtil.css(this.menuEl, 'width', width);
        }

        if (height !== null) {
            LpfnUtil.css(this.menuEl, 'height', height);
        }

        LpfnUtil.css(this.menuEl, 'display', '');
        LpfnUtil.css(this.menuEl, 'overflow', '');

        // Init popper(new)
        this._initDropdownPopper(); 

        LpfnUtil.addClass(this.triggerEl, 'show');
        LpfnUtil.addClass(this.triggerEl, 'menu-dropdown');
        LpfnUtil.addClass(this.menuEl, 'show');

        // Append the sub the the root of the menu
        if (this.menuOverflow) {
            document.body.appendChild(this.menuEl);
        }
    }

    _hideDropdown() {
        LpfnUtil.css(this.menuEl, 'z-index', '');
        LpfnUtil.css(this.menuEl, 'width', '');
        LpfnUtil.css(this.menuEl, 'height', '');

        LpfnUtil.removeClass(this.triggerEl, 'show');
        LpfnUtil.removeClass(this.triggerEl, 'menu-dropdown');
        LpfnUtil.removeClass(this.menuEl, 'show');

        // Append the sub back to it's parent
        if (this.menuOverflow) {
            if (this.triggerEl.classList.contains('menu-item')) {
                this.triggerEl.appendChild(this.menuEl);
            } else {
                LpfnUtil.insertAfter(this.element, this.triggerEl);
            }
            
            LpfnUtil.data(this.triggerEl).remove('sub');
            LpfnUtil.data(this.menuEl).remove('item');
            LpfnUtil.data(this.menuEl).remove('menu');
        } 

        // Destroy popper(new)
        this._destroyDropdownPopper(this.triggerEl);
    }

    _initDropdownPopper() {
        const reference = this.triggerEl.parentNode;
        this._popper = createPopper(reference, this.menuEl, this._getDropdownPopperConfig()); 
    }

    _destroyDropdownPopper() {
        if (this._popper) {
            this._popper.destroy();
        }
    }

    // Prepare popper config for dropdown(see: https://popper.js.org/docs/v2/)
    _getDropdownPopperConfig() {
        var offset = this.menuOffset ? this.menuOffset.split(',') : [];
        
        if (offset.length === 2) {
            offset[0] = parseInt(offset[0]);
            offset[1] = parseInt(offset[1]);
        }

        var strategy = this.menuOverflow ? 'absolute' : 'fixed';

        var altAxis = this.menuFlip !== false ? true : false;

        var popperConfig = {
            placement: this.menuPlacement,
            strategy: strategy,
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: offset
                    }
                }, {
                    name: 'preventOverflow',
                    options: {
                        altAxis: altAxis,
                        padding: 0
                    }
                }, {
                    name: 'flip', 
                    options: {
                        flipVariations: false,
                        padding: 0
                    }
                }
            ]
        };

        return popperConfig;
    }

    _getOptionFromElementAttribute(item, name) {
        var attr;
        var value = null;

        if (item && item.hasAttribute('data-lpfn-menu-' + name)) {
            attr = item.getAttribute('data-lpfn-menu-' + name);
            value = LpfnUtil.getResponsiveValue(attr);

            if (value !== null && String(value) === 'true') {
                value = true;
            } else if (value !== null && String(value) === 'false') {
                value = false;
            }
        }

        return value;
    }
}